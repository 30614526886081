<template>
    <div class="bg-light">
        <div id="form" class="container">
            <main>
                <div class="row g-5">
                    <div class="col-md-12 col-lg-12">
                        <h4 class="mb-3">Biodata Peserta</h4>
                        <form class="needs-validation" @submit.prevent="onSubmit">
                            <div class="row g-3">
                                <div class="col-12">
                                    <label for="namaPesertaID" class="form-label">Nama Peserta</label>
                                    <input type="text" class="form-control" id="namaPesertaID" v-model.trim="nama_peserta"  style="text-transform:uppercase">
                                    <div class="invalid-feedback" :style="{display: style_display_nama_peserta}"> {{ invalid_nama_peserta }} </div>
                                </div>
                                <div class="my-3">
                                    <label class="form-label">Jenis Kelamin</label>
                                    <div class="form-check">
                                        <input id="lakiId" name="jenis_kelamin" type="radio" class="form-check-input" value="L" v-model="jenis_kelamin">
                                        <label class="form-check-label" for="lakiId">Laki - laki</label>
                                    </div>
                                    <div class="form-check">
                                        <input id="perempuanID" name="jenis_kelamin" type="radio" class="form-check-input" value="P" v-model="jenis_kelamin">
                                        <label class="form-check-label" for="perempuanID">Perempuan</label>
                                    </div>
                                    <div class="invalid-feedback" :style="{display: style_display_jenis_kelamin}"> {{ invalid_jenis_kelamin }} </div>
                                </div>
                                <div class="col-12">
                                    <label for="tanggalLahirID" class="form-label">Tanggal Lahir </label><br>
                                    <label class="form-label"><span class="text-muted"> Pilih tanggal, kemudian klik tombol "PILIH" dipojok kanan bawah. Format : dd/mm/yyyy (contoh : 8 juni 1921 -> 8/6/1921)</span></label>
                                    
                                    <VueDatePicker v-model="tanggal_lahir" id="tanggalLahirID" :enable-time-picker="false" :format="format" month-name-format="long" locale="id-ID" select-text="PILIH" cancel-text="Batal" :day-names="['Sn', 'Sl', 'Rb', 'Km', 'Jm', 'Sb', 'Mg']" :max-date="yesterday" />
                                    <div class="invalid-feedback" :style="{display: style_display_tanggal_lahir}"> {{ invalid_tanggal_lahir }} </div>
                                </div>
                                <div class="col-md-4">
                                    <label for="provinsiID" class="form-label">Provinsi</label>
                                    <select class="form-select" id="provinsiID" v-model="provinsi">
                                        <option value="">Pilih...</option>
                                        <option value="010000">Prov. D.K.I. Jakarta</option>
                                        <option value="020000">Prov. Jawa Barat</option>
                                        <option value="030000">Prov. Jawa Tengah</option>
                                        <option value="040000">Prov. D.I. Yogyakarta</option>
                                        <option value="050000">Prov. Jawa Timur</option>
                                        <option value="060000">Prov. Aceh</option>
                                        <option value="070000">Prov. Sumatera Utara</option>
                                        <option value="080000">Prov. Sumatera Barat</option>
                                        <option value="090000">Prov. Riau</option>
                                        <option value="100000">Prov. Jambi</option>
                                        <option value="110000">Prov. Sumatera Selatan</option>
                                        <option value="120000">Prov. Lampung</option>
                                        <option value="130000">Prov. Kalimantan Barat</option>
                                        <option value="140000">Prov. Kalimantan Tengah</option>
                                        <option value="150000">Prov. Kalimantan Selatan</option>
                                        <option value="160000">Prov. Kalimantan Timur</option>
                                        <option value="170000">Prov. Sulawesi Utara</option>
                                        <option value="180000">Prov. Sulawesi Tengah</option>
                                        <option value="190000">Prov. Sulawesi Selatan</option>
                                        <option value="200000">Prov. Sulawesi Tenggara</option>
                                        <option value="210000">Prov. Maluku</option>
                                        <option value="220000">Prov. Bali</option>
                                        <option value="230000">Prov. Nusa Tenggara Barat</option>
                                        <option value="240000">Prov. Nusa Tenggara Timur</option>
                                        <option value="250000">Prov. Papua</option>
                                        <option value="260000">Prov. Bengkulu</option>
                                        <option value="270000">Prov. Maluku Utara</option>
                                        <option value="280000">Prov. Banten</option>
                                        <option value="290000">Prov. Bangka Belitung</option>
                                        <option value="300000">Prov. Gorontalo</option>
                                        <option value="310000">Prov. Kepulauan Riau</option>
                                        <option value="320000">Prov. Papua Barat</option>
                                        <option value="330000">Prov. Sulawesi Barat</option>
                                        <option value="340000">Prov. Kalimantan Utara</option>
                                    </select>
                                    <div class="invalid-feedback" :style="{display: style_display_provinsi}"> {{ invalid_provinsi }} </div>
                                </div>
                                <div class="col-md-4">
                                    <label for="kabKotaId" class="form-label">Kabupaten / Kota</label>
                                    <select class="form-select" id="kabKotaId" v-model="kab_kota">
                                        <option value="">Pilih...</option>
                                        <option v-for="kabkota in kabkotas" :key="kabkota.id" :value="kabkota.id">
                                            {{ kabkota.nama_kab_kota }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback" :style="{display: style_display_kab_kota}"> {{ invalid_kab_kota }} </div>
                                </div>
                                <div class="col-md-4">
                                    <label for="kecamatanId" class="form-label">Kecamatan</label>
                                    <select class="form-select" id="kecamatanId" v-model="kecamatan">
                                        <option value="">Pilih...</option>
                                        <option v-for="kecamatan in kecamatans" :key="kecamatan.id" :value="kecamatan.id">
                                            {{ kecamatan.nama_kecamatan }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback" :style="{display: style_display_kecamatan}"> {{ invalid_kecamatan }} </div>
                                </div>
                                <div class="col-12">
                                    <label for="alamatID" class="form-label">Alamat <span class="text-muted">Tidak Perlu Dimuat Kecamatan, Kabupaten/Kota, dan Provinsi</span></label>
                                    <input type="text" class="form-control" id="alamatID" v-model="alamat">
                                    <div class="invalid-feedback" :style="{display: style_display_alamat}"> {{ invalid_alamat }} </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="country" class="form-label">Pendidikan</label>
                                    <select class="form-select" id="country" v-model="pendidikan">
                                        <option value="">Pilih...</option>
                                        <option value="1">SMK - IPS</option>
                                        <option value="2">SMK - IPA</option>
                                        <option value="3">SMA - IPS</option>
                                        <option value="4">SMA - IPA</option>
                                        <option value="5">D3 - IPS</option>
                                        <option value="6">D3 - IPA</option>
                                        <option value="7">Sarjana - IPS</option>
                                        <option value="8">Sarjana - IPA</option>
                                    </select>
                                    <div class="invalid-feedback" :style="{display: style_display_pendidikan}"> {{ invalid_pendidikan }} </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="lokasiID" class="form-label"> Lokasi Test </label>
                                    <select class="form-select" aria-label="Default select example" v-model="lokasi">
                                    <optgroup label="Kantor Pusat">
                                            <option value="0">Kudus</option>
                                        </optgroup>
                                        <optgroup label="Riau">
                                            <option value="1">Indragiri Hulu</option>
                                            <option value="2">Pekanbaru</option>
                                        </optgroup>
                                        <optgroup label="Jambi">
                                            <option value="3">Sarolangun</option>
                                            <option value="4">Jambi</option>
                                        </optgroup>
                                        <optgroup label="Lampung">
                                            <option value="5">Lampung</option>
                                        </optgroup>
                                        <optgroup label="Banten">
                                            <option value="6">Serang</option>
                                            <option value="7">Tanggerang</option>
                                        </optgroup>
                                        <optgroup label="Jakarta">
                                            <option value="8">Jakarta</option>
                                        </optgroup>
                                        <optgroup label="Jawa Barat">
                                            <option value="9">Cirebon</option>
                                            <option value="10">Subang</option>
                                            <option value="11">Sukabumi</option>
                                            <option value="12">Bandung</option>
                                            <option value="13">Tasikmalaya</option>
                                        </optgroup>
                                        <optgroup label="Jawa Tengah">
                                            <option value="14">Tegal</option>
                                            <option value="15">Purwokerto</option>
                                            <option value="16">Solo</option>
                                            <option value="18">Pekalongan</option>
                                            <option value="19">Semarang</option>
                                            <option value="20">Magelang</option>
                                        </optgroup>
                                        <optgroup label="Yogyakarta">
                                            <option value="17">Yogyakarta</option>
                                        </optgroup>
                                        <optgroup label="Jawa Timur">
                                            <option value="21">Lamongan - Babat</option>
                                            <option value="22">Jombang</option>
                                            <option value="23">Madium</option>
                                            <option value="24">Ngawi</option>
                                            <option value="25">Nganjuk</option>
                                            <option value="26">Surabaya</option>
                                            <option value="27">Malang</option>
                                            <option value="28">Madura</option>
                                        </optgroup>
                                        <optgroup label="Bali">
                                            <option value="29">Bali</option>
                                        </optgroup>
                                        <optgroup label="Lombok">
                                            <option value="30">Lombok</option>
                                        </optgroup>
                                        <optgroup label="Kalimantan Tengah">
                                            <option value="31">Pangkalanbun</option>
                                        </optgroup>
                                        <optgroup label="Kalimantan Selatan">
                                            <option value="32">Banjarmasin</option>
                                        </optgroup>
                                        <optgroup label="Lainnya">
                                            <option value="33">Lainnya</option>
                                        </optgroup>
                                    </select>
                                    <div class="invalid-feedback" :style="{display: style_display_lokasi}"> {{ invalid_lokasi }} </div>
                                </div>
                            </div>
                            <hr class="my-4">
                            <button class="w-100 btn btn-primary btn-lg" type="submit">Kirim</button>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import Swal from 'sweetalert2'
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'

    export default{
        components :{
            VueDatePicker
        },
        data(){
            return {
                nama_peserta 				: '',
                style_display_nama_peserta 	: 'none',
                invalid_nama_peserta 		: '',

                jenis_kelamin 				: '',
                style_display_jenis_kelamin : 'none',
                invalid_jenis_kelamin       : '',

                tanggal_lahir 				: '',
                style_display_tanggal_lahir	: 'none',
                invalid_tanggal_lahir 		: '',

                provinsi 					: '',
                style_display_provinsi 		: 'none',
                invalid_provinsi 			: '',

                kabkotas                    : [],
                kab_kota 					: '',
                style_display_kab_kota 		: 'none',
                invalid_kab_kota 			: '',

                kecamatans 					: [],
                kecamatan 					: '',
                kab_kecamatan 				: '',
                style_display_kecamatan 	: 'none',
                invalid_kecamatan 			: '',

                alamat 						: '',
                style_display_alamat 		: 'none',
                invalid_alamat 				: '',

                pendidikan 					: '',
                style_display_pendidikan 	: 'none',
                invalid_pendidikan 			: '',

                lokasi 						: '',
                style_display_lokasi 		: 'none',
                invalid_lokasi 				: ''
            }
        },
        computed : {
            yesterday(){
                var date = new Date()
                var akhir_bulan = ['31','31','28','31','30','31','30','31','31','30','31','30','31']

                var date_tgl = date.getDate()-1
                var date_bln = date.getMonth()+1
                var date_thn = date.getFullYear()

                var tgl = ''
                var bln = ''

                if(date_tgl<1){
                    date_bln --
                    tgl = akhir_bulan[date_bln]
                } else if(date_tgl<10){
                    tgl = '0'+date_tgl
                } else {
                    tgl = date_tgl
                }

                if(date_bln<1){
                    date_thn --
                    bln = '12'
                } else if(date_bln<10){
                    bln = '0'+date_bln
                } else {
                    bln = date_bln
                }

                return `${date_thn}-${bln}-${tgl}`
            }
        },
        methods : {
            async onSubmit(){
                var par = null

                if(this.$store.getters.getDebug) this.nama_peserta = this.nama_peserta ? this.nama_peserta : "Munji Hanafi"
                if(this.nama_peserta){
                    this.style_display_nama_peserta     = 'none'
                    this.invalid_nama_peserta           = ''
                    var par_nama_peserta                = true
                } else {
                    this.style_display_nama_peserta     = 'block'
                    this.invalid_nama_peserta           = 'Nama Harus Diisi'
                    var par_nama_peserta                = false
                }

                if(this.$store.getters.getDebug) this.jenis_kelamin = this.jenis_kelamin ? this.jenis_kelamin : "L"
                if(this.jenis_kelamin){
                    this.style_display_jenis_kelamin     = 'none'
                    this.invalid_jenis_kelamin           = ''
                    var par_jenis_kelamin                = true
                } else {
                    this.style_display_jenis_kelamin     = 'block'
                    this.invalid_jenis_kelamin           = 'Jenis Kelamin Harus Dipilih'
                    var par_jenis_kelamin                = false
                }

                if(this.$store.getters.getDebug) this.tanggal_lahir = this.tanggal_lahir ? this.tanggal_lahir : new Date("1993-06-10")
                if(this.tanggal_lahir){
                    this.style_display_tanggal_lahir     = 'none'
                    this.invalid_tanggal_lahir           = ''
                    var par_tanggal_lahir                = true
                } else {
                    this.style_display_tanggal_lahir     = 'block'
                    this.invalid_tanggal_lahir           = 'Tanggal Lahir Harus Diisi'
                    var par_tanggal_lahir                = false
                }

                if(this.$store.getters.getDebug) this.provinsi = this.provinsi ? this.provinsi : "010000"
                if(this.provinsi){
                    this.style_display_provinsi     = 'none'
                    this.invalid_provinsi           = ''
                    var par_provinsi                = true
                } else {
                    this.style_display_provinsi     = 'block'
                    this.invalid_provinsi           = 'Provinsi Harus Dipilih'
                    var par_provinsi                = false
                }

                if(this.$store.getters.getDebug) this.kab_kota = this.kab_kota? this.kab_kota : "016000"
                if(this.kab_kota){
                    this.style_display_kab_kota     = 'none'
                    this.invalid_kab_kota           = ''
                    var par_kab_kota                = true
                } else {
                    this.style_display_kab_kota     = 'block'
                    this.invalid_kab_kota           = 'Kabupaten / Kota Harus Dipilih'
                    var par_kab_kota                = false
                }

                if(this.$store.getters.getDebug) this.kecamatan = this.kecamatan ? this.kecamatan :"016005"
                if(this.kecamatan){
                    this.style_display_kecamatan     = 'none'
                    this.invalid_kecamatan           = ''
                    var par_kecamatan                = true
                } else {
                    this.style_display_kecamatan     = 'block'
                    this.invalid_kecamatan           = 'Kecamatan Harus Dipilih'
                    var par_kecamatan                = false
                }

                if(this.$store.getters.getDebug) this.alamat = this.alamat ? this.alamat :"jl percetakan negara"
                if(this.alamat){
                    this.style_display_alamat     = 'none'
                    this.invalid_alamat           = ''
                    var par_alamat                = true
                } else {
                    this.style_display_alamat     = 'block'
                    this.invalid_alamat           = 'Alamat Harus Diisi'
                    var par_alamat                = false
                }

                if(this.$store.getters.getDebug) this.pendidikan = this.pendidikan ? this.pendidikan : 7
                if(this.pendidikan){
                    this.style_display_pendidikan     = 'none'
                    this.invalid_pendidikan           = ''
                    var par_pendidikan                = true
                } else {
                    this.style_display_pendidikan     = 'block'
                    this.invalid_pendidikan           = 'Pendidikan Harus Dipilih'
                    var par_pendidikan                = false
                }

                if(this.$store.getters.getDebug) this.lokasi = this.lokasi ? this.lokasi : 1
                if(this.lokasi){
                    this.style_display_lokasi     = 'none'
                    this.invalid_lokasi           = ''
                    var par_lokasi                = true
                } else {
                    this.style_display_lokasi     = 'block'
                    this.invalid_lokasi           = 'Lokasi Harus Dipilih'
                    var par_lokasi                = false
                }

                par = par_nama_peserta && par_jenis_kelamin && par_tanggal_lahir && par_provinsi && par_kab_kota && par_kecamatan && par_alamat && par_pendidikan && par_lokasi

                if(par){
                    let guard = await this.koorlapGuard()
                    if(!guard) return
                    let obj = {
                        method  : 'post',
                        url     : this.$store.getters.getServerURL+'biodata',
                        data    : {
                            nama_peserta    : this.nama_peserta.toUpperCase(),
                            jenis_kelamin   : this.jenis_kelamin,
                            tanggal_lahir   : `${this.tanggal_lahir.getFullYear()}-${(this.tanggal_lahir.getMonth() + 1)}-${this.tanggal_lahir.getDate()}`,
                            kecamatan_id    : this.kecamatan,
                            alamat          : this.alamat,
                            pendidikan_id   : this.pendidikan,
                            lokasi          : this.lokasi
                        }
                    }
                    console.log('REQ :',obj)
                    try{
                        var response = await axios(obj)
                        console.log('RES :',response.data)
                        var data = response.data.data[0]
                        console.log('BiodataView > onSubmit > data :',data)
                        this.$store.dispatch('setPageBiodata',true)

                        this.$store.dispatch('setId',data.peserta_id)
                        this.$store.dispatch('setLokasi',this.lokasi)
                        this.$store.dispatch('setPageCfitInst1',data.cfit_petunjuk1)
                        this.$store.dispatch('setPageCfitSoal1',data.cfit_tes1)
                        this.$store.dispatch('setPageCfitInst2',data.cfit_petunjuk2)
                        this.$store.dispatch('setPageCfitSoal2',data.cfit_tes2)
                        this.$store.dispatch('setPageCfitInst3',data.cfit_petunjuk3)
                        this.$store.dispatch('setPageCfitSoal3',data.cfit_tes3)
                        this.$store.dispatch('setPageCfitInst4',data.cfit_petunjuk4)
                        this.$store.dispatch('setPageCfitSoal4',data.cfit_tes4)
                        this.$store.dispatch('setPageKraepelinInst',data.kraepelin_petunjuk)
                        this.$store.dispatch('setPageKraepelinSim1',data.kraepelin_sim1)
                        this.$store.dispatch('setPageKraepelinSim2',data.kraepelin_sim2)
                        this.$store.dispatch('setPageKraepelin1',data.kraepelin1)
                        this.$store.dispatch('setPageKraepelin2',data.kraepelin2)
                        this.$store.dispatch('setPageKraepelin3',data.kraepelin3)
                        this.$store.dispatch('setPageKraepelin4',data.kraepelin4)
                        this.$store.dispatch('setPageKraepelin5',data.kraepelin5)
                        this.$store.dispatch('setPageKraepelin6',data.kraepelin6)
                        this.$store.dispatch('setPageKraepelin7',data.kraepelin7)
                        this.$store.dispatch('setPageKraepelin8',data.kraepelin8)
                        this.$store.dispatch('setPageKraepelin9',data.kraepelin9)
                        this.$store.dispatch('setPageKraepelin10',data.kraepelin10)
                        this.$store.dispatch('setPageKraepelin11',data.kraepelin11)
                        this.$store.dispatch('setPageKraepelin12',data.kraepelin12)
                        this.$store.dispatch('setPageKraepelin13',data.kraepelin13)
                        this.$store.dispatch('setPageKraepelin14',data.kraepelin14)
                        this.$store.dispatch('setPageKraepelin15',data.kraepelin15)
                        this.$store.dispatch('setPageKraepelin16',data.kraepelin16)
                        this.$store.dispatch('setPageKraepelin17',data.kraepelin17)
                        this.$store.dispatch('setPageKraepelin18',data.kraepelin18)
                        this.$store.dispatch('setPageKraepelin19',data.kraepelin19)
                        this.$store.dispatch('setPageKraepelin20',data.kraepelin20)
                        this.$store.dispatch('setPageKraepelin21',data.kraepelin21)
                        this.$store.dispatch('setPageKraepelin22',data.kraepelin22)
                        this.$store.dispatch('setPageKraepelin23',data.kraepelin23)
                        this.$store.dispatch('setPageKraepelin24',data.kraepelin24)
                        this.$store.dispatch('setPageKraepelin25',data.kraepelin25)
                        this.$store.dispatch('setPageKraepelin26',data.kraepelin26)
                        this.$store.dispatch('setPageKraepelin27',data.kraepelin27)
                        this.$store.dispatch('setPageKraepelin28',data.kraepelin28)
                        this.$store.dispatch('setPageKraepelin29',data.kraepelin29)
                        this.$store.dispatch('setPageKraepelin30',data.kraepelin30)
                        this.$store.dispatch('setPageKraepelin31',data.kraepelin31)
                        this.$store.dispatch('setPageKraepelin32',data.kraepelin32)
                        this.$store.dispatch('setPageKraepelin33',data.kraepelin33)
                        this.$store.dispatch('setPageKraepelin34',data.kraepelin34)
                        this.$store.dispatch('setPageKraepelin35',data.kraepelin35)
                        this.$store.dispatch('setPageKraepelin36',data.kraepelin36)
                        this.$store.dispatch('setPageKraepelin37',data.kraepelin37)
                        this.$store.dispatch('setPageKraepelin38',data.kraepelin38)
                        this.$store.dispatch('setPageKraepelin39',data.kraepelin39)
                        this.$store.dispatch('setPageKraepelin40',data.kraepelin40)
                        this.$store.dispatch('setPageKraepelin41',data.kraepelin41)
                        this.$store.dispatch('setPageKraepelin42',data.kraepelin42)
                        this.$store.dispatch('setPageKraepelin43',data.kraepelin43)
                        this.$store.dispatch('setPageKraepelin44',data.kraepelin44)
                        this.$store.dispatch('setPageKraepelin45',data.kraepelin45)
                        this.$store.dispatch('setPageKraepelin46',data.kraepelin46)
                        this.$store.dispatch('setPageKraepelin47',data.kraepelin47)
                        this.$store.dispatch('setPageKraepelin48',data.kraepelin48)
                        this.$store.dispatch('setPageKraepelin49',data.kraepelin49)
                        this.$store.dispatch('setPageKraepelin50',data.kraepelin50)
                        this.$store.dispatch('setPagePapiInst',data.papi_kostick_petunjuk)
                        this.$store.dispatch('setPagePapiSoal',data.papi_kostick_soal)
                        this.$router.replace({ name: 'cfitinst1' })
                        // this.$router.replace({ name: 'kraepelin1' })
                    } catch(error){
                        console.error(error)
                    }
                }
            },
            async getKabkotas(){
                if(!this.provinsi) return
                if(this.provinsi != this.$store.getters.getTempProvinsi){
                    $("#kabKotaId").LoadingOverlay("show", {image : "",fontawesome : "fa fa-cog fa-spin"})
                    this.$store.dispatch('setTempProvinsi',this.provinsi)
                    try{
                        var response = await axios.get(this.$store.getters.getServerURL+'biodata/kab-kota/'+this.provinsi)
                        this.kabkotas   = response.data.data
                        this.kecamatans = []
                    } catch(error) {
                        console.error(error)
                        this.kabkotas = []
                        this.kecamatans = []
                    } finally {
                        $("#kabKotaId").LoadingOverlay("hide", true)
                    }
                }
            },
            async getKecamatan(){
                if(!this.kab_kota) return
                if(this.kab_kota != this.$store.getters.getTempKabKota){
                    $("#kecamatanId").LoadingOverlay("show", {image : "",fontawesome : "fa fa-cog fa-spin"})
                    this.$store.dispatch('setTempKabKota',this.kab_kota)
                    try{
                        var response = await axios.get(this.$store.getters.getServerURL+'biodata/kecamatan/'+this.kab_kota)
                        this.kecamatans = response.data.data
                    } catch(error) {
                        console.error(error)
                        this.kecamatans = []
                    } finally {
                        $("#kecamatanId").LoadingOverlay("hide", true)
                    }
                }
            },
            async koorlapGuard(){
                try {
                    var response = await axios.get(this.$store.getters.getServerURL+'par/lokasi/'+this.lokasi)
                    if(!response.data.data){
                        Swal.fire({
                            title: 'Peringatan',
                            text: response.data.error_desc,
                            icon: 'warning',
                            confirmButtonText: 'Oke'
                        })
                        return false
                    }
                    return true
                } catch(error) {
                    console.error(error)
                    return false
                }
            },
            async adminGuard(){
                try {
                    var response = await axios.get(this.$store.getters.getServerURL+'par/biodata/')
                    if(!response.data.data){
                        window.location.replace("https://close.sukunsigaretpsikotes.com/")
                    }
                } catch(error) {
                    console.error(error)
                    return false
                }
            },
            format(date){
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();

                return `${day}/${month}/${year}`;
            }
        },
        async updated(){
            await this.getKabkotas()
            await this.getKecamatan()
        },
        async mounted(){
            console.log('WELCOME BIODATA')
            if(this.$store.getters.getPageBiodata){
                this.$router.replace({ name: 'cfitinst1' })
                // this.$router.replace({ name: 'kraepelin1' })
                return
            }
            // try {
            //     var response = await axios.get(this.$store.getters.getServerURL+'test')
            // } catch (error){
            //     console.error(error)
            //     window.location.replace("https://alt.sukunsigaretpsikotes.com/")
            // }
        },
        unmounted(){
            this.$store.dispatch('setTempProvinsi','')
            this.$store.dispatch('setTempKabKota','')
            console.log('GOOD BYE BIODATA')
        }
    }
</script>